// This file establishes the connection to the firebase database

import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/auth'

// Firebase credentials
var firebaseConfig = {
  apiKey: 'AIzaSyBL4mnfw7mMNA85k9ylXrzXjRjaLZcWp9Y',
  authDomain: 'wetter-station-hsd.firebaseapp.com',
  databaseURL: 'https://wetter-station-hsd.firebaseio.com',
  projectId: 'wetter-station-hsd',
  storageBucket: 'wetter-station-hsd.appspot.com',
  messagingSenderId: '1079300047838',
  appId: '1:1079300047838:web:0077835abeacdd2a2ed34f',
  measurementId: 'G-KWVGQ75M4W'
}

// Setup firebase
firebase.initializeApp(firebaseConfig)

// Setup google analytics
firebase.analytics()

// Setup firestore
const db = firebase.firestore()

// === Firestore Collection ===

const stations = db.collection('stations')
const capabilities = db.collection('capabilities')

// Firebase Authentication
const auth = firebase.auth()

// Export reusable modules
module.exports = { firebase, auth, db, stations, capabilities }
